.root {
  /* acts like min-width in a cell */
  height: calc(4.5 * var(--mini-unit));
  text-align: left;
  padding: var(--mini-unit) calc(1.5 * var(--mini-unit));
  box-sizing: border-box;
}
.header {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-bold);
  font-size: var(--font-size-2);
    line-height: 1.14;
  color: var(--palette-text-500);
  padding: var(--spacing-3);
}
.body {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-2);
  line-height: 1.14;
  color: var(--palette-text-500);
}
.alignCenter {
  text-align: center;
}
.alignEnd {
  text-align: right;
}
