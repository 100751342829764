.title {
  text-transform: uppercase;
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-bold);
  font-size: var(--font-size-3);
  margin: 0;
  line-height: 1.3;
  color: var(--palette-text-500);
}
.subTitle {
  color: var(--palette-text-100);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-2);
  margin: 0;
  line-height: 1.285;
}
.info {
}
.amount {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  font-size: var(--font-size-6);
  line-height: 1;
  color: var(--palette-text-500);
  margin: 0;
}
.amountLabel {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  font-size: var(--font-size-2);
  color: var(--palette-text-500);
  margin: 0;
}
.triggered {
  color: var(--palette-error-500);
}
.tooltip {
  color: var(--palette-text-500);
  padding: 3px;
}
