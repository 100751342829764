.bodyText {
  font-size: var(--font-size-3);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  line-height: 1.3;
  color: var(--palette-text-500);
}

.textArea {
  height: calc(12 * var(--mini-unit));
}

.sitesToggle {
  margin-bottom: var(--spacing-3);
}
