.root:not(:first-child) {
    border-top: 1px solid var(--palette-divider);
  }

.root[aria-selected="true"] .container {
    outline-width: 3px;
    outline-color: Highlight;
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
  }

.container {
  min-height: calc(4 * var(--mini-unit));
  padding: var(--mini-unit) calc(2.5 * var(--mini-unit));
  box-sizing: border-box;
}

.container:hover {
    background: var(--palette-grey-100);
  }

.title {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  font-size: var(--font-size-3);
  line-height: 1;
  color: var(--palette-text-900);
}

.titleWithDetails {
  font-size: var(--font-size-3);
}

.details {
  padding-top: 8px;
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-2);
  line-height: 1.14;
  color: var(--palette-text-100);
}

.link {
  display: block;
  text-decoration: none;
}
