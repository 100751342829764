.comments {
  flex: auto;
  overflow: auto;
}
.close {
  position: absolute;
  width: 40px;
  height: 40px;
  left: -40px;

  padding: 0px;

  background-color: #FFFFFF;

  border-width: 1px;
  border-right-width: 0px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.25);
  border-radius: 4px 0px 0px 4px;
}
.username {
  font-family: var(--font-family-secondary);
  font-weight: var(--font-weight-secondary-bold);
  font-size: var(--font-size-6);
  line-height: 0.67;
}
.userDetail {
}
.userDetailValue {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-3);
  line-height: 1;
  color: var(--palette-text-500);
}
.icon {
  padding-top: 2px;
}
.copy {
}
.divider {
  border-bottom: 1px solid var(--palette-grey-300);
}
.userStatus {
}
.userStatusLabel {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-bold);
  font-size: var(--font-size-2);
  line-height: 1.14;
  color: var(--palette-text-500);
  margin-right: var(--spacing-1);
}
.userStatusChange {
}
.notAvailable {
  color: var(--palette-text-100);
}
