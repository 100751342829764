.button {
  justify-content: space-between;
  display: flex;
}

.bordered {
  padding: 0 var(--spacing-2);
  border: 1px solid var(--palette-grey-400);
}

.fullWidth {
  width: 100%;
}

.dropdownButton {
  min-width: 80px;
}
