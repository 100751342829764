.usernameColumn {
  vertical-align: top;
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 15px;
}
.emailColumn {
  vertical-align: top;
  word-break: break-word;
  padding-top: 15px;
  padding-bottom: 15px;
}
.memberSinceColumn {
  vertical-align: top;
  white-space: nowrap;
  padding-top: 15px;
  padding-bottom: 15px;
}
.roleColumn {
}
.statusColumn {
}
.usernameButton {
  padding: 0px;
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  font-size: var(--font-size-2);
  line-height: 1.14;
  word-break: break-word;
  white-space: break-spaces;
}
.username {
  display: inline-block;
}
.deleted {
  display: inline-block;
  padding-left: var(--spacing-2);
  color: #DC8400;
}
.notAvailable {
  color: var(--palette-text-100);
}
