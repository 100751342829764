$moderateCardIconColor: var(--palette-text-100);
$moderateCardInReplyToColor: var(--palette-text-100);
$moderateCardInReplyToUsernameColor: var(--palette-text-500);
$moderateCardUsernameBackgroundHover: var(--palette-grey-200);

.icon {
  color: $moderateCardIconColor;
}
.inReplyTo {
  color: $moderateCardInReplyToColor;
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-2);
  line-height:  1.14;
}
.username {
  color: $moderateCardInReplyToUsernameColor;
  font-family: var(--font-family-secondary);
  font-weight: var(--font-weight-secondary-bold);
  font-size: var(--font-size-3);
  line-height: 1;

  padding: var(--spacing-1);

  &:hover {
    background-color: $moderateCardUsernameBackgroundHover;
    border-radius: var(--round-corners);
    border-style: none;
  }

  &:active {
    background-color: $moderateCardUsernameBackgroundHover;
    border-radius: var(--round-corners);
    border-style: none;
  }
}

.usernameButton {
  vertical-align: baseline;

  margin-left: calc(-1 * var(--spacing-1));
}
