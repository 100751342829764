.root {
  min-width: 475px;
  padding: var(--spacing-2) var(--spacing-3) var(--spacing-6)
    var(--spacing-3);
}
.hotKeyContainer {
  width: 65px;
}
.title {
  font-size: var(--font-size-5);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-bold);
  line-height: 1.2;
  color: var(--palette-text-500);
  margin: 0;
}
.subTitle {
  font-size: var(--font-size-4);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  line-height: 1.11;
  color: var(--palette-text-500);
  margin: 0;
}
.hotKeyAction {
  font-size: var(--font-size-3);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  line-height: 1.3;
  color: var(--palette-text-500);
  margin: 0;
}
.hotKey {
  background-color: #EFEFEF;
  border: 1px solid var(--palette-grey-400);
  color: var(--palette-text-500);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  border-radius: 3px;
  padding: var(--spacing-1);
  display: inline-block;
  line-height: 1;
}
