.background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: var(--palette-grey-100);
}
.main {
  margin: calc(2 * var(--mini-unit)) 0 calc(4 * var(--mini-unit)) 0;
  display: flex;
  justify-content: center;
}
.subBar {
  height: calc(4 * var(--mini-unit));
  background-color: #11435D;
  margin-top: -1px;
}
.subBarBegin {
  position: absolute;
  left: 0;
  font-family: var(--font-family-primary);
  text-decoration: none;
  font-weight: var(--font-weight-primary-semi-bold);
  font-size: var(--font-size-2);
  text-transform: uppercase;
  color: var(--palette-text-000);
}
.subBarTitle {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  font-size: var(--font-size-3);
  text-transform: uppercase;
  color: var(--palette-text-000);
}
