.root {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  z-index: 1000;
}

.baseScroll {
  position: relative;

  width: 100%;
  height: 100%;
}

.noScroll {
  overflow-y: hidden;
}

.scroll {
  overflow-y: auto;
}

.alignContainer1 {
  display: table;
  margin: 0 auto;
  height: 100%;
}

.alignContainer2 {
  display: table-cell;
  vertical-align: middle;
}

.wrapper {
  pointer-events: all;
}
