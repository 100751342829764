.root {
}
.body {
  font-family: var(--font-family-primary);
  font-size: var(--font-size-3);
  font-weight: var(--font-weight-primary-regular);
  line-height: 1.3;
  background-color: var(--palette-grey-100);
  border-radius: 4px;
  padding: var(--spacing-3);
  color: var(--palette-text-500);
  margin-bottom: var(--spacing-2);
}
.bodyType {
}
.leftBy {
  font-family: var(--font-family-primary);
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-primary-regular);
  line-height: 1.14;
  padding-left: var(--spacing-4);
  padding-right: var(--spacing-1);
  position: relative;
  color: var(--palette-text-500);
}
.leftBy:before {
  content: "";
  width: 4px;
  height: 4px;
  background-color: ;
  position: absolute;
  border-radius: 50%;
  left: var(--spacing-1);
  background-color: var(--palette-text-100);
  top: 50%;
}
.username {
  font-family: var(--font-family-secondary);
  font-weight: var(--font-weight-secondary-bold);
  font-size: var(--font-size-3);
  line-height: 1;
  color: var(--palette-text-900);
}
.footerLeft {
  padding-left: var(--spacing-3);
}
