.container {
  width: 350px;
  background-color: var(--palette-grey-100);
  border: 1px solid var(--palette-grey-300);
  margin-top: 70px;
  box-sizing: border-box;
}

.textLink {
  font-weight: var(--font-weight-primary-semi-bold);
  font-family: var(--font-family-primary);
  color: #2C7B8C;
  font-size: var(--font-size-2);
  line-height: 1.14;
  text-decoration: underline;
}
