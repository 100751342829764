.moderatedBy {
  font-size: var(--font-size-1);
  font-weight: var(--font-weight-primary-semi-bold);
  font-family: var(--font-family-primary);
  line-height: 1.33;
  color: var(--palette-text-100);
  text-transform: uppercase;
}
.moderatedByUsername {
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-secondary-bold);
  font-family: var(--font-family-secondary);
  line-height: 1.14;
  color: var(--palette-text-900);
  text-align: center;
  padding-top: 1px;
}
