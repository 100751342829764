.root {
  position: absolute;
  width: 20px;
  height: 20px;
}
.root[data-placement*="bottom"] {
    top: 0;
    left: 0;
    margin-top: -6px;
    width: 20px;
    height: 6px;
    filter: drop-shadow(0px -2px 1px rgba(0, 0, 0, 0.1));
  }
.root[data-placement*="bottom"]::before {
      border-width: 0 10px 6px 10px;
      border-color: transparent transparent var(--palette-grey-400)
        transparent;
    }
.root[data-placement*="bottom"]::after {
      margin-top: -5px;
      border-width: 0 10px 6px 10px;
      border-color: transparent transparent var(--palette-background-popover)
        transparent;
    }
.root[data-placement*="bottom"].colorDark::before {
        border-color: transparent transparent var(--palette-background-tooltip)
          transparent;
      }
.root[data-placement*="bottom"].colorDark::after {
        border-color: transparent transparent var(--palette-background-tooltip)
          transparent;
      }
.root[data-placement*="top"] {
    bottom: 0;
    left: 0;
    margin-bottom: -6px;
    width: 20px;
    height: 6px;
    filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.1));
  }
.root[data-placement*="top"]::before {
      border-width: 6px 10px 0 10px;
      border-color: var(--palette-grey-400) transparent transparent
        transparent;
    }
.root[data-placement*="top"]::after {
      margin-top: -7px;
      border-width: 6px 10px 0 10px;
      border-color: var(--palette-background-popover) transparent transparent
        transparent;
    }
.root[data-placement*="top"].colorDark::before {
        border-color: var(--palette-background-tooltip) transparent transparent
          transparent;
      }
.root[data-placement*="top"].colorDark::after {
        border-color: var(--palette-background-tooltip) transparent transparent
          transparent;
      }
.root[data-placement*="right"] {
    left: 0;
    height: 20px;
    width: 6px;
    margin-left: -6px;
    filter: drop-shadow(-1px 0px 1px rgba(0, 0, 0, 0.1));
  }
.root[data-placement*="right"]::before {
      border-width: 10px 6px 10px 0;
      border-color: transparent var(--palette-grey-400) transparent
        transparent;
    }
.root[data-placement*="right"]::after {
      margin-top: -20px;
      margin-right: -1px;
      border-width: 10px 6px 10px 0;
      border-color: transparent var(--palette-background-popover) transparent
        transparent;
    }
.root[data-placement*="right"].colorDark::before {
        border-color: transparent var(--palette-background-tooltip) transparent
          transparent;
      }
.root[data-placement*="right"].colorDark::after {
        border-color: transparent var(--palette-background-tooltip) transparent
          transparent;
      }
.root[data-placement*="left"] {
    right: 0;
    height: 20px;
    width: 6px;
    margin-right: -6px;
    filter: drop-shadow(1px 0px 1px rgba(0, 0, 0, 0.1));
  }
.root[data-placement*="left"]::before {
      border-width: 10px 0 10px 6px;
      border-color: transparent transparent transparent
        var(--palette-grey-400);
    }
.root[data-placement*="left"]::after {
      margin-top: -20px;
      margin-left: -1px;
      border-width: 10px 0 10px 6px;
      border-color: transparent transparent transparent
        var(--palette-background-popover);
    }
.root[data-placement*="left"].colorDark::before {
        border-color: transparent transparent transparent
          var(--palette-background-tooltip);
      }
.root[data-placement*="left"].colorDark::after {
        border-color: transparent transparent transparent
          var(--palette-background-tooltip);
      }
.root::before {
    content: "";
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
  }
.root::after {
    content: "";
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
  }
