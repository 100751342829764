.root {
  display: flex;
  width: 100%;
  align-items: center;
}
.input {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-3);
  line-height: 1;

  display: block;
  width: 100%;
  align-self: stretch;
  margin-top: 0;
  margin-bottom: 0;

  padding: var(--spacing-2);

  background-color: var(--palette-background-input);
  color: var(--palette-text-500);

  border-radius: var(--round-corners);
  border-style: solid;
}
.input:-moz-read-only {
    background-color: var(--palette-background-input-disabled);
    border-color: var(--palette-grey-400);
    color: var(--palette-text-input-disabled);
  }
.input:read-only,
  .input:disabled {
    background-color: var(--palette-background-input-disabled);
    border-color: var(--palette-grey-400);
    color: var(--palette-text-input-disabled);
  }
.input:focus {
    outline: none;
  }
.colorRegular {
  border: 1px solid var(--palette-grey-500);
}
.colorRegular:focus {
    border: 1px solid #59C3C3;
    box-shadow: inset 0px 0px 0px 1px #59C3C3;
  }
.colorStreamBlue {
  border: 1px solid var(--palette-grey-500);
}
.colorStreamBlue:focus {
    border: 1px solid var(--palette-primary-500);
    box-shadow: inset 0px 0px 0px 1px var(--palette-primary-500);
  }
.colorError {
  border: 2px solid var(--palette-error-500);
}
