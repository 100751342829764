.tabBar {
  margin-top: var(--spacing-3);
}

.tab {
}

.activeTab {
  border-bottom: 1px solid var(--color-teal-500);
}

.tabIcon {
  margin-right: var(--spacing-1);
}

.configureStory {
  padding-top: var(--spacing-1);
  font-family: var(--font-family-secondary);
  font-weight: var(--font-weight-secondary-regular);
  color: var(--palette-text-500);
}

.setting {
  padding: var(--spacing-1) 0;
  align-items: center;
}

.submit {
  width: 100px;
  margin-top: var(--spacing-1);
}

.setting > input {
  vertical-align: bottom;
}

.checkboxText {
  font-family: var(--font-family-secondary);
  font-weight: var(--font-weight-secondary-regular);
}
