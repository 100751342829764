.root {
  background-color: var(--palette-error-300);

  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  font-size: var(--font-size-3);
  line-height: 1;
  color: var(--palette-text-500);

  border-radius: var(--round-corners);

  padding: var(--spacing-3);
}
