/* Design tokens */

/* flat button */
$button-flat-regular-foreground: $colors-teal-600;
$button-flat-mono-foreground: var(--palette-grey-500);
$button-flat-alert-foreground: var(--palette-error-500);
$button-flat-alt-foreground: var(--palette-success-500);
$button-flat-dark-foreground: $colors-teal-900;

$button-flat-background: inherit;

$button-flat-regular-background-hover: $colors-teal-100;
$button-flat-mono-background-hover: var(--palette-grey-200);
$button-flat-alert-background-hover: var(--palette-error-100);
$button-flat-alt-background-hover: var(--palette-success-100);
$button-flat-dark-background-hover: $colors-teal-100;

$button-flat-regular-background-active: $colors-teal-200;
$button-flat-mono-background-active: var(--palette-grey-300);
$button-flat-alert-background-active: var(--palette-error-200);
$button-flat-alt-background-active: var(--palette-success-200);
$button-flat-dark-background-active: $colors-teal-200;

$button-flat-foreground-disabled: var(--palette-grey-400);

/* regular button */
$button-regular-foreground: var(--palette-text-000);

$button-regular-regular-background: $colors-teal-600;
$button-regular-mono-background: var(--palette-grey-500);
$button-regular-alert-background: var(--palette-error-500);
$button-regular-alt-background: var(--palette-success-500);
$button-regular-dark-background: $colors-teal-900;
$button-regular-stream-background: var(--palette-primary-500);

$button-regular-regular-background-hover: $colors-teal-700;
$button-regular-mono-background-hover: var(--palette-grey-600);
$button-regular-alert-background-hover: var(--palette-error-600);
$button-regular-alt-background-hover: var(--palette-success-600);
$button-regular-dark-background-hover: $colors-teal-800;
$button-regular-stream-background-hover: var(--palette-primary-600);

$button-regular-regular-background-active: $colors-teal-800;
$button-regular-mono-background-active: var(--palette-grey-700);
$button-regular-alert-background-active: var(--palette-error-700);
$button-regular-alt-background-active: var(--palette-success-700);
$button-regular-dark-background-active: $colors-teal-700;
$button-regular-stream-background-active: var(--palette-primary-700);

$button-regular-background-disabled: var(--palette-grey-300);
$button-regular-stream-background-disabled: var(--palette-primary-200);

/* outline button */
$button-outline-background: inherit;
$button-outline-background-disabled: var(--palette-grey-300);
$button-outline-foreground-disabled: var(--palette-text-000);
$button-outline-foreground-hover: var(--palette-text-000);
$button-outline-foreground-active: var(--palette-text-000);

$button-outline-regular-foreground: $colors-teal-600;
$button-outline-mono-foreground: var(--palette-grey-500);
$button-outline-alert-foreground: var(--palette-error-500);
$button-outline-alt-foreground: var(--palette-success-500);
$button-outline-dark-foreground: $colors-teal-900;
$button-outline-stream-foreground: var(--palette-primary-500);

$button-outline-regular-border: $colors-teal-600;
$button-outline-mono-border: var(--palette-grey-500);
$button-outline-alert-border: var(--palette-error-500);
$button-outline-alt-border: var(--palette-success-500);
$button-outline-dark-border: $colors-teal-900;
$button-outline-stream-border: var(--palette-primary-500);

$button-outline-regular-background-hover: $colors-teal-600;
$button-outline-mono-background-hover: var(--palette-grey-500);
$button-outline-alert-background-hover: var(--palette-error-500);
$button-outline-alt-background-hover: var(--palette-success-500);
$button-outline-dark-background-hover: $colors-teal-900;
$button-outline-stream-background-hover: var(--palette-primary-500);

$button-outline-regular-background-active: $colors-teal-800;
$button-outline-mono-background-active: var(--palette-grey-700);
$button-outline-alert-background-active: var(--palette-error-700);
$button-outline-alt-background-active: var(--palette-success-700);
$button-outline-dark-background-active: $colors-teal-700;
$button-outline-stream-background-active: var(--palette-primary-500);

$button-outline-regular-border-active: $colors-teal-800;
$button-outline-mono-border-active: var(--palette-grey-700);
$button-outline-alert-border-active: var(--palette-error-700);
$button-outline-alt-border-active: var(--palette-success-700);
$button-outline-dark-border-active: $colors-teal-700;
$button-outline-stream-border-active: var(--palette-primary-500);

/* text button */
$button-text-background: transparent;
$button-text-foreground-disabled: var(--palette-text-000);

$button-text-regular-foreground: $colors-teal-600;
$button-text-mono-foreground: var(--palette-grey-500);
$button-text-alert-foreground: var(--palette-error-500);
$button-text-alt-foreground: var(--palette-success-500);
$button-text-dark-foreground: $colors-teal-900;
$button-text-stream-foreground: var(--palette-primary-500);

$button-text-regular-foreground-hover: $colors-teal-700;
$button-text-mono-foreground-hover: var(--palette-grey-600);
$button-text-alert-foreground-hover: var(--palette-error-600);
$button-text-alt-foreground-hover: var(--palette-success-600);
$button-text-dark-foreground-hover: $colors-teal-800;
$button-text-stream-foreground-hover: var(--palette-primary-700);

$button-text-regular-foreground-active: $colors-teal-800;
$button-text-mono-foreground-active: var(--palette-grey-700);
$button-text-alert-foreground-active: var(--palette-error-700);
$button-text-alt-foreground-active: var(--palette-success-700);
$button-text-dark-foreground-active: $colors-teal-700;
$button-text-stream-foreground-active: var(--palette-primary-700);

/* Class Definitions */

.root {
  border-radius: var(--round-corners);
  background-color: transparent;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid transparent;

  transition: 0.2s ease-out background-color;

  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-bold);
  line-height: 1;

  & > * {
    margin: 0 var(--spacing-1) 0 0;
    /* convince safari */
    align-self: center;
  }
  & > *:last-child {
    margin: 0;
  }

  &.iconLeft i {
    padding-right: var(--spacing-1);
  }

  &.iconRight i {
    padding-left: var(--spacing-1);
  }
}

.adornmentLeft {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.adornmentRight {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.uppercase {
  text-transform: uppercase;
  letter-spacing: 0.042em;
}

.root.disabled {
  opacity: 0.4;
  cursor: default;
}

.fullWidth {
  display: flex;
  width: 100%;
  box-sizing: border-box;
}

.sizeSmall {
  font-size: var(--font-size-1);
  &:not(.variantText) {
    padding: var(--spacing-1) var(--spacing-2);
    &.iconLeft,
    &.iconRight {
      padding-left: var(--spacing-2);
      padding-right: var(--spacing-2);
    }
  }
}

.sizeRegular {
  font-size: var(--font-size-1);
  &:not(.variantText) {
    padding: var(--spacing-2) var(--spacing-3);
    &.iconLeft,
    &.iconRight {
      padding-left: var(--spacing-2);
      padding-right: var(--spacing-2);
    }
  }
}

.sizeLarge {
  font-size: var(--font-size-2);
  &:not(.variantText) {
    padding: var(--spacing-3) var(--spacing-5);
    &.iconLeft,
    &.iconRight {
      padding-left: var(--spacing-4);
      padding-right: var(--spacing-4);
    }
  }
}

.variantRegular {
  color: $button-regular-foreground;

  &:disabled,
  &.disabled {
    &.colorRegular {
      background-color: $button-regular-background-disabled;
    }
    &.colorStream {
      background-color: $button-regular-stream-background-disabled;
    }
    &.colorMono {
      background-color: $button-regular-background-disabled;
    }
    &.colorAlert {
      background-color: $button-regular-background-disabled;
    }
    &.colorAlt {
      background-color: $button-regular-background-disabled;
    }
    &.colorMono {
      background-color: $button-regular-background-disabled;
    }
  }

  &:not(.disabled),
  &:not(:disabled) {
    &.colorRegular {
      background-color: $button-regular-regular-background;
      &:hover,
      &.mouseHover {
        background-color: $button-regular-regular-background-hover;
      }
      &:active,
      &.active {
        background-color: $button-regular-regular-background-active;
      }
    }

    &.colorStream {
      background-color: $button-regular-stream-background;
      &:hover,
      &.mouseHover {
        background-color: $button-regular-stream-background-hover;
      }
      &:active,
      &.active {
        background-color: $button-regular-stream-background-active;
      }
    }

    &.colorMono {
      background-color: $button-regular-mono-background;
      &:hover,
      &.mouseHover {
        background-color: $button-regular-mono-background-hover;
      }
      &:active,
      &.active {
        background-color: $button-regular-mono-background-active;
      }
    }

    &.colorAlert {
      background-color: $button-regular-alert-background;
      &:hover,
      &.mouseHover {
        background-color: $button-regular-alert-background-hover;
      }
      &:active,
      &.active {
        background-color: $button-regular-alert-background-active;
      }
    }

    &.colorAlt {
      background-color: $button-regular-alt-background;
      &:hover,
      &.mouseHover {
        background-color: $button-regular-alt-background-hover;
      }
      &:active,
      &.active {
        background-color: $button-regular-alt-background-active;
      }
    }

    &.colorDark {
      background-color: $button-regular-dark-background;
      &:hover,
      &.mouseHover {
        background-color: $button-regular-dark-background-hover;
      }
      &:active,
      &.active {
        background-color: $button-regular-dark-background-active;
      }
    }
  }
}

.variantFlat {
  background-color: $button-flat-background;

  &:disabled,
  &.disabled {
    color: $button-flat-foreground-disabled;
  }

  &:not(.disabled),
  &:not(:disabled) {
    &.colorRegular {
      color: $button-flat-regular-foreground;
      &:hover,
      &.mouseHover {
        background-color: $button-flat-regular-background-hover;
      }
      &:active,
      &.active {
        background-color: $button-flat-regular-background-active;
      }
    }
    &.colorMono {
      color: $button-flat-mono-foreground;
      &:hover,
      &.mouseHover {
        background-color: $button-flat-mono-background-hover;
      }
      &:active,
      &.active {
        color: $button-flat-mono-background-active;
      }
    }
    &.colorAlert {
      color: $button-flat-alert-foreground;
      &:hover,
      &.mouseHover {
        background-color: $button-flat-alert-background-hover;
      }
      &:active,
      &.active {
        background-color: $button-flat-alert-background-active;
      }
    }
    &.colorAlt {
      color: $button-flat-alt-foreground;
      &:hover,
      &.mouseHover {
        background-color: $button-flat-alt-background-hover;
      }
      &:active,
      &.active {
        background-color: $button-flat-alt-background-active;
      }
    }
    &.colorDark {
      color: $button-flat-dark-foreground;
      &:hover,
      &.mouseHover {
        background-color: $button-flat-dark-background-hover;
      }
      &:active,
      &.active {
        background-color: $button-flat-dark-background-active;
      }
    }
  }
}

.variantOutline {
  background-color: $button-outline-background;
  &:disabled,
  &.disabled {
    color: $button-outline-foreground-disabled;
    background-color: $button-outline-background-disabled;
  }

  &:not(.disabled),
  &:not(:disabled),
  &:not(.mouseHover),
  &:not(:hover),
  &:not(.active),
  &:not(:active) {
    &.colorRegular {
      color: $button-outline-regular-foreground;
      border-color: $button-outline-regular-border;
    }
    &.colorStream {
      color: $button-outline-stream-foreground;
      border-color: $button-outline-stream-border;
    }
    &.colorMono {
      color: $button-outline-mono-foreground;
      border-color: $button-outline-mono-border;
    }
    &.colorAlert {
      color: $button-outline-alert-foreground;
      border-color: $button-outline-alert-border;
    }
    &.colorAlt {
      color: $button-outline-alt-foreground;
      border-color: $button-outline-alt-border;
    }
    &.colorDark {
      color: $button-outline-dark-foreground;
      border-color: $button-outline-dark-border;
    }
  }

  &:not(.disabled),
  &:not(:disabled) {
    &.mouseHover,
    &:hover {
      color: $button-outline-foreground-hover;
      &.colorRegular {
        background-color: $button-outline-regular-background-hover;
      }
      &.colorStream {
        background-color: $button-outline-stream-background-hover;
      }
      &.colorMono {
        background-color: $button-outline-mono-background-hover;
      }
      &.colorAlert {
        background-color: $button-outline-alert-background-hover;
      }
      &.colorAlt {
        background-color: $button-outline-alt-background-hover;
      }
      &.colorDark {
        background-color: $button-outline-dark-background-hover;
      }
    }

    &.active,
    &:active {
      color: $button-outline-foreground-active;
      &.colorRegular {
        background-color: $button-outline-regular-background-active;
        border-color: $button-outline-regular-border-active;
      }
      &.colorStream {
        background-color: $button-outline-stream-background-active;
        border-color: $button-outline-stream-border-active;
      }
      &.colorMono {
        background-color: $button-outline-mono-background-active;
        border-color: $button-outline-regular-border-active;
      }
      &.colorAlert {
        background-color: $button-outline-alert-background-active;
        border-color: $button-outline-alert-border-active;
      }
      &.colorAlt {
        background-color: $button-outline-alt-background-active;
        border-color: $button-outline-alt-border-active;
      }
      &.colorDark {
        background-color: $button-outline-dark-background-active;
        border-color: $button-outline-dark-border-active;
      }
    }
  }
}

.variantText {
  background-color: $button-text-background;
  &:disabled,
  &.disabled {
    color: $button-text-foreground-disabled;
  }

  &:not(.disabled),
  &:not(:disabled) {
    &.colorRegular {
      color: $button-text-regular-foreground;
      &:hover,
      &.mouseHover {
        color: $button-text-regular-foreground-hover;
      }
      &:active,
      &.active {
        color: $button-text-regular-foreground-active;
      }
    }

    &.colorMono {
      color: $button-text-mono-foreground;
      &:hover,
      &.mouseHover {
        color: $button-text-mono-foreground-hover;
      }
      &:active,
      &.active {
        color: $button-text-mono-foreground-active;
      }
    }

    &.colorAlt {
      color: $button-text-alt-foreground;
      &:hover,
      &.mouseHover {
        color: $button-text-alt-foreground-hover;
      }
      &:active,
      &.active {
        color: $button-text-alt-foreground-active;
      }
    }

    &.colorAlert {
      color: $button-text-alert-foreground;
      &:hover,
      &.mouseHover {
        color: $button-text-alert-foreground-hover;
      }
      &:active,
      &.active {
        color: $button-text-alert-foreground-active;
      }
    }

    &.colorDark {
      color: $button-text-dark-foreground;
      &:hover,
      &.mouseHover {
        color: $button-text-dark-foreground-hover;
      }
      &:active,
      &.active {
        color: $button-text-dark-foreground-active;
      }
    }

    &.colorStream {
      color: $button-text-stream-foreground;
      &:hover,
      &.mouseHover {
        color: $button-text-stream-foreground-hover;
      }
      &:active,
      &.active {
        color: $button-text-stream-foreground-active;
      }
    }
  }
}

.variantUnderlined {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
  border-bottom: 1px solid currentColor;
  padding: 0;

  &.colorRegular {
    color: $button-text-regular-foreground;
  }
  &.colorMono {
    color: $button-text-mono-foreground;
  }
  &.colorAlt {
    color: $button-text-alt-foreground;
  }
  &.colorAlert {
    color: $button-text-alert-foreground;
  }
  &.colorDark {
    color: $button-text-dark-foreground;
  }

  &:not(.disabled) {
    &.colorRegular {
      &.mouseHover {
        color: $button-text-regular-foreground-hover;
      }
      &:active,
      &.active {
        color: $button-text-regular-foreground-active;
      }
    }

    &.colorMono {
      &.mouseHover {
        color: $button-text-mono-foreground-hover;
      }
      &:active,
      &.active {
        color: $button-text-mono-foreground-active;
      }
    }

    &.colorAlt {
      &.mouseHover {
        color: $button-text-alt-foreground-hover;
      }
      &:active,
      &.active {
        color: $button-text-alt-foreground-active;
      }
    }

    &.colorAlert {
      &.mouseHover {
        color: $button-text-alert-foreground-hover;
      }
      &:active,
      &.active {
        color: $button-text-alert-foreground-active;
      }
    }

    &.colorDark {
      &.mouseHover {
        color: $button-text-dark-foreground-hover;
      }
      &:active,
      &.active {
        color: $button-text-dark-foreground-active;
      }
    }
  }
}

.variantTextUnderlined {
  font-weight: var(--font-weight-primary-bold);
  font-family: var(--font-family-primary);
  text-decoration: underline;

  &.colorRegular {
    color: $button-text-regular-foreground;
  }
  &.colorMono {
    color: $button-text-mono-foreground;
  }
  &.colorAlert {
    color: $button-text-alert-foreground;
  }

  &:not(.disabled) {
    &.colorRegular {
      &.mouseHover {
        color: $button-text-regular-foreground-hover;
        & > span {
          text-decoration: underline;
        }
      }
      &.active,
      &:active {
        color: $button-text-regular-foreground-active;
      }
    }

    &.colorMono {
      &.mouseHover {
        color: $button-text-mono-foreground-hover;
        & > span {
          text-decoration: underline;
        }
      }
      &.active,
      &:active {
        color: $button-text-mono-foreground-active;
      }
    }

    &.colorAlert {
      &.mouseHover {
        color: $button-text-alert-foreground-hover;
        & > span {
          text-decoration: variantOutlined;
        }
      }
      &.active,
      &:active {
        color: $button-text-alert-foreground-active;
      }
    }
  }
}

.linkButton {
  text-decoration: none;
}

.underline {
  text-decoration: underline;
}
