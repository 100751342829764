.root {
  width: calc(29 * var(--mini-unit));
  height: 36px;
  line-height: 36px;
  box-sizing: border-box;
}


.input {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-3);
  line-height: 1;
  display: block;
  padding: var(--spacing-2);
  border-radius: var(--round-corners);
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}


.input:-moz-read-only {
    background-color: var(--palette-background-input-disabled);
  }


.input:read-only {
    background-color: var(--palette-background-input-disabled);
  }


.input:disabled {
    background-color: var(--palette-background-input-disabled);
    border-color: var(--palette-text-input-disabled);
  }


.input:focus {
    outline: none;
  }


.input::-moz-placeholder {
    color: var(--palette-text-placeholder);
  }


.input:-ms-input-placeholder {
    color: var(--palette-text-placeholder);
  }


.input::placeholder {
    color: var(--palette-text-placeholder);
  }


.colorRegular {
  background-color: var(--palette-background-input);
  color: var(--palette-text-500);
  border: 1px solid var(--palette-grey-500);
}


.colorRegular:focus {
    border: 1px solid #59C3C3;
    box-shadow: inset 0px 0px 0px 1px #59C3C3;
  }


.colorStreamBlue {
  background-color: var(--palette-background-input);
  color: var(--palette-text-500);
  border: 1px solid var(--palette-grey-500);
}


.colorStreamBlue:focus {
    border: 1px solid var(--palette-primary-500);
    box-shadow: inset 0px 0px 0px 1px var(--palette-primary-500);
  }


.colorError {
  background-color: var(--palette-background-input);
  border-color: var(--palette-error-500);
  border: 2px solid var(--palette-error-500);
}


.fullWidth {
  width: 100%;
}


.wrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}


.icon {
  position: absolute;
  display: inline-block;
  right: 0px;
  padding: 4px calc(1 * var(--mini-unit));
  cursor: pointer;
  line-height: 0;
}
