.root {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.root > * {
    border-bottom: 1px solid var(--palette-grey-300);
  }

.root > *:last-child {
    border-bottom: 0;
  }
