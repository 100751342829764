.root {
  border: 1px solid var(--palette-success-500);
  box-sizing: border-box;
  border-radius: var(--round-corners);
  width: 65px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--palette-success-500);
}

.root:not(:disabled):active {
    background-color: var(--palette-success-500);
    color: var(--palette-text-000);
  }

.readOnly {
  background-color: transparent;
  border-color: #CBD1D2);
  color: #CBD1D2);
}

.readOnly:hover {
    cursor: not-allowed;
  }

.readOnly {
  background-color: transparent;
  border-color: #CBD1D2;
  color: #CBD1D2;
}

.readOnly:hover {
    cursor: not-allowed;
  }

.invert {
  background-color: var(--palette-success-500);
  border-color: var(--palette-success-500);
  color: var(--palette-text-000);
}

.icon {
  font-weight: var(--font-weight-primary-semi-bold);
  color: inherit;
}
