.link {
  color: var(--palette-text-100);
  font-family: var(--font-family-primary);
  font-size: var(--font-size-4);
  line-height: 1.11;
  font-weight: var(--font-weight-primary-regular);
  display: inline-block;
  padding: var(--spacing-2) var(--spacing-2)
    var(--spacing-2) calc(var(--spacing-3) + 4px);
  border-left: 1px solid var(--palette-grey-300);
  text-decoration: none;
}
.link:hover {
    cursor: pointer;
    border-left: 2px solid #F77160;
    padding-left: calc(var(--spacing-3) + 3px);
    color: var(--palette-text-500);
  }
.linkActive, .linkActive:hover {
  border-left: 4px solid #F77160;
  padding-left: var(--spacing-3);
  color: var(--palette-text-500);
  font-weight: var(--font-weight-primary-bold);
}
