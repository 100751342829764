.root {
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-primary-bold);
  font-family: var(--font-family-primary);
  line-height: 1.14;
  text-transform: uppercase;
  color: #2C7B8C;
  width: 100%;
  border-top: 1px solid var(--palette-grey-300);
  text-align: center;
  padding-top: var(--spacing-1);
  padding-bottom: var(--spacing-1);
}
.root:disabled {
    color: var(--palette-grey-300);
  }
