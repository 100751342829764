.root[aria-selected="true"] {
    outline-width: 3px;
    outline-color: Highlight;
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
  }

.link {
  display: flex;
  justify-content: center;
  align-items: center;

  background: #11435D;
  min-height: calc(3 * var(--mini-unit));

  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  font-size: var(--font-size-3);
  line-height: 1;
  color: #EFEFEF;

  text-decoration: none;
  text-transform: uppercase;
}

.link:hover {
    background: #2C7B8C;
  }

.icon {
  font-weight: var(--font-weight-primary-semi-bold);
  padding-left: calc(0.5 * var(--mini-unit));
  line-height: calc(16em / 13);
  margin-top: -2px;

  color: #EFEFEF;
}
