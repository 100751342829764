.root {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  color: var(--palette-text-500);
}

.commenter {
  color: var(--palette-text-500);
  font-weight: var(--font-weight-primary-regular);
}
