.title {
  text-transform: uppercase;
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-bold);
  font-size: var(--font-size-3);
  margin: 0;
  line-height: 1.3;
  color: var(--palette-text-500);
}
.contents {
  color: var(--palette-text-100);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-2);
  margin: 0;
  line-height: 1.285;
}
