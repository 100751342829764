.root {
  flex: 1 1;
  min-height: 105px;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 28px;
  height: 28px;
  border-radius: 50%;
}

.tealIcon {
  background-color: #E2FAF7;
  color: #419EA7;
}

.redIcon {
  background-color: var(--palette-error-100);
  color: var(--palette-error-500);
}

.greyIcon {
  background-color: var(--palette-grey-200);
  color: var(--palette-grey-500);
}

.blueIcon {
  background-color: #D2DCF6;
  color: #17255A;
}

.outer {
  height: 100%;
}

.inner {
  height: 100%;
}
