.subLink {
  border-left: 1px solid var(--palette-grey-300);
  color: var(--palette-text-100);
  font-family: var(--font-family-primary);
  font-size: var(--font-size-4);
  line-height: 1.11;
  padding: var(--spacing-2) var(--spacing-2)
    var(--spacing-2) calc(var(--spacing-5) + 4px);
}
.subLink:hover {
    border-left: 2px solid #F77160;
    color: var(--palette-text-500);
    padding-left: calc(var(--spacing-5) + 3px);
  }
.subLinkActive, .subLinkActive:hover {
  color: var(--palette-text-500);
  font-weight: var(--font-weight-primary-bold);
}
