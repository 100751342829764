.root {
}

.flex {
  display: flex;
}

.spacing-1 > * {
    margin: 0 var(--spacing-1) 0 0 !important;
  }

.spacing-1.directionRowReverse > * {
      margin: 0 0 0 var(--spacing-1) !important;
    }

.spacing-1.directionColumn > * {
      margin: 0 0 var(--spacing-1) 0 !important;
    }

.spacing-1.directionColumnReverse > * {
      margin: var(--spacing-1) 0 0 0 !important;
    }

.spacing-1 > *:last-child {
    margin: 0 !important;
  }

.spacing-2 > * {
    margin: 0 var(--spacing-2) 0 0 !important;
  }

.spacing-2.directionRowReverse > * {
      margin: 0 0 0 var(--spacing-2) !important;
    }

.spacing-2.directionColumn > * {
      margin: 0 0 var(--spacing-2) 0 !important;
    }

.spacing-2.directionColumnReverse > * {
      margin: var(--spacing-2) 0 0 0 !important;
    }

.spacing-2 > *:last-child {
    margin: 0 !important;
  }

.spacing-3 > * {
    margin: 0 var(--spacing-3) 0 0 !important;
  }

.spacing-3.directionRowReverse > * {
      margin: 0 0 0 var(--spacing-3) !important;
    }

.spacing-3.directionColumn > * {
      margin: 0 0 var(--spacing-3) 0 !important;
    }

.spacing-3.directionColumnReverse > * {
      margin: var(--spacing-3) 0 0 0 !important;
    }

.spacing-3 > *:last-child {
    margin: 0 !important;
  }

.spacing-4 > * {
    margin: 0 var(--spacing-4) 0 0 !important;
  }

.spacing-4.directionRowReverse > * {
      margin: 0 0 0 var(--spacing-4) !important;
    }

.spacing-4.directionColumn > * {
      margin: 0 0 var(--spacing-4) 0 !important;
    }

.spacing-4.directionColumnReverse > * {
      margin: var(--spacing-4) 0 0 0 !important;
    }

.spacing-4 > *:last-child {
    margin: 0 !important;
  }

.spacing-5 > * {
    margin: 0 var(--spacing-5) 0 0 !important;
  }

.spacing-5.directionRowReverse > * {
      margin: 0 0 0 var(--spacing-5) !important;
    }

.spacing-5.directionColumn > * {
      margin: 0 0 var(--spacing-5) 0 !important;
    }

.spacing-5.directionColumnReverse > * {
      margin: var(--spacing-5) 0 0 0 !important;
    }

.spacing-5 > *:last-child {
    margin: 0 !important;
  }

.spacing-6 > * {
    margin: 0 var(--spacing-6) 0 0 !important;
  }

.spacing-6.directionRowReverse > * {
      margin: 0 0 0 var(--spacing-6) !important;
    }

.spacing-6.directionColumn > * {
      margin: 0 0 var(--spacing-6) 0 !important;
    }

.spacing-6.directionColumnReverse > * {
      margin: var(--spacing-6) 0 0 0 !important;
    }

.spacing-6 > *:last-child {
    margin: 0 !important;
  }

.spacing-7 > * {
    margin: 0 var(--spacing-7) 0 0 !important;
  }

.spacing-7.directionRowReverse > * {
      margin: 0 0 0 var(--spacing-7) !important;
    }

.spacing-7.directionColumn > * {
      margin: 0 0 var(--spacing-7) 0 !important;
    }

.spacing-7.directionColumnReverse > * {
      margin: var(--spacing-7) 0 0 0 !important;
    }

.spacing-7 > *:last-child {
    margin: 0 !important;
  }

.spacing-8 > * {
    margin: 0 var(--spacing-8) 0 0 !important;
  }

.spacing-8.directionRowReverse > * {
      margin: 0 0 0 var(--spacing-8) !important;
    }

.spacing-8.directionColumn > * {
      margin: 0 0 var(--spacing-8) 0 !important;
    }

.spacing-8.directionColumnReverse > * {
      margin: var(--spacing-8) 0 0 0 !important;
    }

.spacing-8 > *:last-child {
    margin: 0 !important;
  }

.spacing-9 > * {
    margin: 0 var(--spacing-9) 0 0 !important;
  }

.spacing-9.directionRowReverse > * {
      margin: 0 0 0 var(--spacing-9) !important;
    }

.spacing-9.directionColumn > * {
      margin: 0 0 var(--spacing-9) 0 !important;
    }

.spacing-9.directionColumnReverse > * {
      margin: var(--spacing-9) 0 0 0 !important;
    }

.spacing-9 > *:last-child {
    margin: 0 !important;
  }

.halfItemGutter > * {
    margin: 0 calc(0.5 * var(--mini-unit)) 0 0 !important;
  }

.halfItemGutter.directionRowReverse > * {
      margin: 0 0 0 calc(0.5 * var(--mini-unit)) !important;
    }

.halfItemGutter.directionColumn > * {
      margin: 0 0 calc(0.5 * var(--mini-unit)) 0 !important;
    }

.halfItemGutter.directionColumnReverse > * {
      margin: calc(0.5 * var(--mini-unit)) 0 0 0 !important;
    }

.halfItemGutter > *:last-child {
    margin: 0 !important;
  }

.doubleItemGutter > * {
    margin: 0 calc(2 * var(--mini-unit)) 0 0 !important;
  }

.doubleItemGutter.directionRowReverse > * {
      margin: 0 0 0 calc(2 * var(--mini-unit)) !important;
    }

.doubleItemGutter.directionColumn > * {
      margin: 0 0 calc(2 * var(--mini-unit)) 0 !important;
    }

.doubleItemGutter.directionColumnReverse > * {
      margin: calc(2 * var(--mini-unit)) 0 0 0 !important;
    }

.doubleItemGutter > *:last-child {
    margin: 0 !important;
  }

.itemGutter > * {
    margin: 0 var(--mini-unit) 0 0 !important;
  }

.itemGutter.directionRowReverse > * {
      margin: 0 0 0 var(--mini-unit) !important;
    }

.itemGutter.directionColumn > * {
      margin: 0 0 var(--mini-unit) 0 !important;
    }

.itemGutter.directionColumnReverse > * {
      margin: var(--mini-unit) 0 0 0 !important;
    }

.itemGutter > *:last-child {
    margin: 0 !important;
  }

.wrap {
  flex-wrap: wrap;
}

.wrapReverse {
  flex-wrap: wrap-reverse;
}

.wrap:not(.directionColumn).itemGutter:not(:empty), .wrapReverse:not(.directionColumn).itemGutter:not(:empty) {
      margin-top: calc(-1 * var(--mini-unit)) !important;
    }

.wrap:not(.directionColumn).itemGutter > *, .wrapReverse:not(.directionColumn).itemGutter > * {
      margin-top: var(--mini-unit) !important;
    }

.wrap.directionColumn.itemGutter:not(:empty), .wrapReverse.directionColumn.itemGutter:not(:empty) {
      margin-left: calc(-1 * var(--mini-unit)) !important;
    }

.wrap.directionColumn.itemGutter.itemGutter > *, .wrapReverse.directionColumn.itemGutter.itemGutter > * {
      margin-left: var(--mini-unit) !important;
    }

.wrap:not(.directionColumn).halfItemGutter:not(:empty), .wrapReverse:not(.directionColumn).halfItemGutter:not(:empty) {
      margin-top: calc(-0.5 * var(--mini-unit)) !important;
    }

.wrap:not(.directionColumn).halfItemGutter > *, .wrapReverse:not(.directionColumn).halfItemGutter > * {
      margin-top: calc(0.5 * var(--mini-unit)) !important;
    }

.wrap.directionColumn.halfItemGutter:not(:empty), .wrapReverse.directionColumn.halfItemGutter:not(:empty) {
      margin-left: calc(-0.5 * var(--mini-unit)) !important;
    }

.wrap.directionColumn.halfItemGutter.halfItemGutter > *, .wrapReverse.directionColumn.halfItemGutter.halfItemGutter > * {
      margin-left: calc(0.5 * var(--mini-unit)) !important;
    }

.wrap:not(.directionColumn).doubleItemGutter:not(:empty), .wrapReverse:not(.directionColumn).doubleItemGutter:not(:empty) {
      margin-top: calc(-2 * var(--mini-unit)) !important;
    }

.wrap:not(.directionColumn).doubleItemGutter > *, .wrapReverse:not(.directionColumn).doubleItemGutter > * {
      margin-top: calc(2 * var(--mini-unit)) !important;
    }

.wrap.directionColumn.doubleItemGutter:not(:empty), .wrapReverse.directionColumn.doubleItemGutter:not(:empty) {
      margin-left: calc(-2 * var(--mini-unit)) !important;
    }

.wrap.directionColumn.doubleItemGutter.doubleItemGutter > *, .wrapReverse.directionColumn.doubleItemGutter.doubleItemGutter > * {
      margin-left: calc(2 * var(--mini-unit)) !important;
    }

.wrap:not(.directionColumn).tripleItemGutter:not(:empty), .wrapReverse:not(.directionColumn).tripleItemGutter:not(:empty) {
      margin-top: calc(-3 * var(--mini-unit)) !important;
    }

.wrap:not(.directionColumn).tripleItemGutter > *, .wrapReverse:not(.directionColumn).tripleItemGutter > * {
      margin-top: calc(3 * var(--mini-unit)) !important;
    }

.wrap.directionColumn.tripleItemGutter:not(:empty), .wrapReverse.directionColumn.tripleItemGutter:not(:empty) {
      margin-left: calc(-3 * var(--mini-unit)) !important;
    }

.wrap.directionColumn.tripleItemGutter.tripleItemGutter > *, .wrapReverse.directionColumn.tripleItemGutter.tripleItemGutter > * {
      margin-left: calc(3 * var(--mini-unit)) !important;
    }

.wrap:not(.directionColumn).spacing-1:not(:empty), .wrapReverse:not(.directionColumn).spacing-1:not(:empty) {
      margin-top: calc(-1 * var(--spacing-1)) !important;
    }

.wrap:not(.directionColumn).spacing-1 > *, .wrapReverse:not(.directionColumn).spacing-1 > * {
      margin-top: var(--spacing-1) !important;
    }

.wrap.directionColumn.spacing-1:not(:empty), .wrapReverse.directionColumn.spacing-1:not(:empty) {
      margin-left: calc(-1 * var(--spacing-1)) !important;
    }

.wrap.directionColumn.spacing-1.itemGutter > *, .wrapReverse.directionColumn.spacing-1.itemGutter > * {
      margin-left: var(--spacing-1) !important;
    }

.wrap:not(.directionColumn).spacing-2:not(:empty), .wrapReverse:not(.directionColumn).spacing-2:not(:empty) {
      margin-top: calc(-1 * var(--spacing-2)) !important;
    }

.wrap:not(.directionColumn).spacing-2 > *, .wrapReverse:not(.directionColumn).spacing-2 > * {
      margin-top: var(--spacing-2) !important;
    }

.wrap.directionColumn.spacing-2:not(:empty), .wrapReverse.directionColumn.spacing-2:not(:empty) {
      margin-left: calc(-1 * var(--spacing-2)) !important;
    }

.wrap.directionColumn.spacing-2.itemGutter > *, .wrapReverse.directionColumn.spacing-2.itemGutter > * {
      margin-left: var(--spacing-2) !important;
    }

.wrap:not(.directionColumn).spacing-3:not(:empty), .wrapReverse:not(.directionColumn).spacing-3:not(:empty) {
      margin-top: calc(-1 * var(--spacing-3)) !important;
    }

.wrap:not(.directionColumn).spacing-3 > *, .wrapReverse:not(.directionColumn).spacing-3 > * {
      margin-top: var(--spacing-3) !important;
    }

.wrap.directionColumn.spacing-3:not(:empty), .wrapReverse.directionColumn.spacing-3:not(:empty) {
      margin-left: calc(-1 * var(--spacing-3)) !important;
    }

.wrap.directionColumn.spacing-3.itemGutter > *, .wrapReverse.directionColumn.spacing-3.itemGutter > * {
      margin-left: var(--spacing-3) !important;
    }

.wrap:not(.directionColumn).spacing-4:not(:empty), .wrapReverse:not(.directionColumn).spacing-4:not(:empty) {
      margin-top: calc(-1 * var(--spacing-4)) !important;
    }

.wrap:not(.directionColumn).spacing-4 > *, .wrapReverse:not(.directionColumn).spacing-4 > * {
      margin-top: var(--spacing-4) !important;
    }

.wrap.directionColumn.spacing-4:not(:empty), .wrapReverse.directionColumn.spacing-4:not(:empty) {
      margin-left: calc(-1 * var(--spacing-4)) !important;
    }

.wrap.directionColumn.spacing-4.itemGutter > *, .wrapReverse.directionColumn.spacing-4.itemGutter > * {
      margin-left: var(--spacing-4) !important;
    }

.wrap:not(.directionColumn).spacing-5:not(:empty), .wrapReverse:not(.directionColumn).spacing-5:not(:empty) {
      margin-top: calc(-1 * var(--spacing-5)) !important;
    }

.wrap:not(.directionColumn).spacing-5 > *, .wrapReverse:not(.directionColumn).spacing-5 > * {
      margin-top: var(--spacing-5) !important;
    }

.wrap.directionColumn.spacing-5:not(:empty), .wrapReverse.directionColumn.spacing-5:not(:empty) {
      margin-left: calc(-1 * var(--spacing-5)) !important;
    }

.wrap.directionColumn.spacing-5.itemGutter > *, .wrapReverse.directionColumn.spacing-5.itemGutter > * {
      margin-left: var(--spacing-5) !important;
    }

.wrap:not(.directionColumn).spacing-6:not(:empty), .wrapReverse:not(.directionColumn).spacing-6:not(:empty) {
      margin-top: calc(-1 * var(--spacing-6)) !important;
    }

.wrap:not(.directionColumn).spacing-6 > *, .wrapReverse:not(.directionColumn).spacing-6 > * {
      margin-top: var(--spacing-6) !important;
    }

.wrap.directionColumn.spacing-6:not(:empty), .wrapReverse.directionColumn.spacing-6:not(:empty) {
      margin-left: calc(-1 * var(--spacing-6)) !important;
    }

.wrap.directionColumn.spacing-6.itemGutter > *, .wrapReverse.directionColumn.spacing-6.itemGutter > * {
      margin-left: var(--spacing-6) !important;
    }

.wrap:not(.directionColumn).spacing-7:not(:empty), .wrapReverse:not(.directionColumn).spacing-7:not(:empty) {
      margin-top: calc(-1 * var(--spacing-7)) !important;
    }

.wrap:not(.directionColumn).spacing-7 > *, .wrapReverse:not(.directionColumn).spacing-7 > * {
      margin-top: var(--spacing-7) !important;
    }

.wrap.directionColumn.spacing-7:not(:empty), .wrapReverse.directionColumn.spacing-7:not(:empty) {
      margin-left: calc(-1 * var(--spacing-7)) !important;
    }

.wrap.directionColumn.spacing-7.itemGutter > *, .wrapReverse.directionColumn.spacing-7.itemGutter > * {
      margin-left: var(--spacing-7) !important;
    }

.wrap:not(.directionColumn).spacing-8:not(:empty), .wrapReverse:not(.directionColumn).spacing-8:not(:empty) {
      margin-top: calc(-1 * var(--spacing-8)) !important;
    }

.wrap:not(.directionColumn).spacing-8 > *, .wrapReverse:not(.directionColumn).spacing-8 > * {
      margin-top: var(--spacing-8) !important;
    }

.wrap.directionColumn.spacing-8:not(:empty), .wrapReverse.directionColumn.spacing-8:not(:empty) {
      margin-left: calc(-1 * var(--spacing-8)) !important;
    }

.wrap.directionColumn.spacing-8.itemGutter > *, .wrapReverse.directionColumn.spacing-8.itemGutter > * {
      margin-left: var(--spacing-8) !important;
    }

.wrap:not(.directionColumn).spacing-9:not(:empty), .wrapReverse:not(.directionColumn).spacing-9:not(:empty) {
      margin-top: calc(-1 * var(--spacing-9)) !important;
    }

.wrap:not(.directionColumn).spacing-9 > *, .wrapReverse:not(.directionColumn).spacing-9 > * {
      margin-top: var(--spacing-9) !important;
    }

.wrap.directionColumn.spacing-9:not(:empty), .wrapReverse.directionColumn.spacing-9:not(:empty) {
      margin-left: calc(-1 * var(--spacing-9)) !important;
    }

.wrap.directionColumn.spacing-9.itemGutter > *, .wrapReverse.directionColumn.spacing-9.itemGutter > * {
      margin-left: var(--spacing-9) !important;
    }

.justifyFlexStart {
  justify-content: flex-start;
}

.justifyFlexEnd {
  justify-content: flex-end;
}

.justifyCenter {
  justify-content: center;
}

.justifySpaceBetween {
  justify-content: space-between;
}

.justifySpaceAround {
  justify-content: space-around;
}

.justifySpaceEvenly {
  justify-content: space-evenly;
}

.alignFlexStart {
  align-items: flex-start;
}

.alignFlexEnd {
  align-items: flex-end;
}

.alignCenter {
  align-items: center;
}

.alignBaseline {
  align-items: baseline;
}

.alignStretch {
  align-items: stretch;
}

.directionRow {
  flex-direction: row;
}

.directionColumn {
  flex-direction: column;
}

.directionRowReverse {
  flex-direction: row-reverse;
}

.directionColumnReverse {
  flex-direction: column-reverse;
}
