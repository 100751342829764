.icon {
  color: var(--palette-text-100);
}
.inReplyTo {
  color: var(--palette-text-100);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-regular);
  font-size: var(--font-size-2);
  line-height:  1.14;
}
.username {
  color: var(--palette-text-500);
  font-family: var(--font-family-secondary);
  font-weight: var(--font-weight-secondary-bold);
  font-size: var(--font-size-3);
  line-height: 1;

  padding: var(--spacing-1);
}
.username:hover {
    background-color: var(--palette-grey-200);
    border-radius: var(--round-corners);
    border-style: none;
  }
.username:active {
    background-color: var(--palette-grey-200);
    border-radius: var(--round-corners);
    border-style: none;
  }
.usernameButton {
  vertical-align: baseline;

  margin-left: calc(-1 * var(--spacing-1));
}
