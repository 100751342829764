.value {
  width: calc(6 * var(--mini-unit));
}

.select {
  height: 100%;
  min-width: calc(17 * var(--mini-unit));
}

.unit {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  font-size: var(--font-size-2);
  line-height: 36px;
}
