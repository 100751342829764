.root {
}

/* Margin */

.ml-1 {
  margin-left: var(--spacing-1) !important;
}

.mr-1 {
  margin-right: var(--spacing-1) !important;
}

.mt-1 {
  margin-top: var(--spacing-1) !important;
}

.mb-1 {
  margin-bottom: var(--spacing-1) !important;
}

.ml-2 {
  margin-left: var(--spacing-2) !important;
}

.mr-2 {
  margin-right: var(--spacing-2) !important;
}

.mt-2 {
  margin-top: var(--spacing-2) !important;
}

.mb-2 {
  margin-bottom: var(--spacing-2) !important;
}

.ml-3 {
  margin-left: var(--spacing-3) !important;
}

.mr-3 {
  margin-right: var(--spacing-3) !important;
}

.mt-3 {
  margin-top: var(--spacing-3) !important;
}

.mb-3 {
  margin-bottom: var(--spacing-3) !important;
}

.ml-4 {
  margin-left: var(--spacing-4) !important;
}

.mr-4 {
  margin-right: var(--spacing-4) !important;
}

.mt-4 {
  margin-top: var(--spacing-4) !important;
}

.mb-4 {
  margin-bottom: var(--spacing-4) !important;
}

.ml-5 {
  margin-left: var(--spacing-5) !important;
}

.mr-5 {
  margin-right: var(--spacing-5) !important;
}

.mt-5 {
  margin-top: var(--spacing-5) !important;
}

.mb-5 {
  margin-bottom: var(--spacing-5) !important;
}

.ml-6 {
  margin-left: var(--spacing-6) !important;
}

.mr-6 {
  margin-right: var(--spacing-6) !important;
}

.mt-6 {
  margin-top: var(--spacing-6) !important;
}

.mb-6 {
  margin-bottom: var(--spacing-6) !important;
}

.ml-7 {
  margin-left: var(--spacing-7) !important;
}

.mr-7 {
  margin-right: var(--spacing-7) !important;
}

.mt-7 {
  margin-top: var(--spacing-7) !important;
}

.mb-7 {
  margin-bottom: var(--spacing-7) !important;
}

.ml-8 {
  margin-left: var(--spacing-8) !important;
}

.mr-8 {
  margin-right: var(--spacing-8) !important;
}

.mt-8 {
  margin-top: var(--spacing-8) !important;
}

.mb-8 {
  margin-bottom: var(--spacing-8) !important;
}

.ml-9 {
  margin-left: var(--spacing-9) !important;
}

.mr-9 {
  margin-right: var(--spacing-9) !important;
}

.mt-9 {
  margin-top: var(--spacing-9) !important;
}

.mb-9 {
  margin-bottom: var(--spacing-9) !important;
}

/* Padding */

.pl-1 {
  padding-left: var(--spacing-1) !important;
}

.pr-1 {
  padding-right: var(--spacing-1) !important;
}

.pt-1 {
  padding-top: var(--spacing-1) !important;
}

.pb-1 {
  padding-bottom: var(--spacing-1) !important;
}

.pl-2 {
  padding-left: var(--spacing-2) !important;
}

.pr-2 {
  padding-right: var(--spacing-2) !important;
}

.pt-2 {
  padding-top: var(--spacing-2) !important;
}

.pb-2 {
  padding-bottom: var(--spacing-2) !important;
}

.pl-3 {
  padding-left: var(--spacing-3) !important;
}

.pr-3 {
  padding-right: var(--spacing-3) !important;
}

.pt-3 {
  padding-top: var(--spacing-3) !important;
}

.pb-3 {
  padding-bottom: var(--spacing-3) !important;
}

.pl-4 {
  padding-left: var(--spacing-4) !important;
}

.pr-4 {
  padding-right: var(--spacing-4) !important;
}

.pt-4 {
  padding-top: var(--spacing-4) !important;
}

.pb-4 {
  padding-bottom: var(--spacing-4) !important;
}

.pl-5 {
  padding-left: var(--spacing-5) !important;
}

.pr-5 {
  padding-right: var(--spacing-5) !important;
}

.pt-5 {
  padding-top: var(--spacing-5) !important;
}

.pb-5 {
  padding-bottom: var(--spacing-5) !important;
}

.pl-6 {
  padding-left: var(--spacing-6) !important;
}

.pr-6 {
  padding-right: var(--spacing-6) !important;
}

.pt-6 {
  padding-top: var(--spacing-6) !important;
}

.pb-6 {
  padding-bottom: var(--spacing-6) !important;
}

.pl-7 {
  padding-left: var(--spacing-7) !important;
}

.pr-7 {
  padding-right: var(--spacing-7) !important;
}

.pt-7 {
  padding-top: var(--spacing-7) !important;
}

.pb-7 {
  padding-bottom: var(--spacing-7) !important;
}

.pl-8 {
  padding-left: var(--spacing-8) !important;
}

.pr-8 {
  padding-right: var(--spacing-8) !important;
}

.pt-8 {
  padding-top: var(--spacing-8) !important;
}

.pb-8 {
  padding-bottom: var(--spacing-8) !important;
}

.pl-9 {
  padding-left: var(--spacing-9) !important;
}

.pr-9 {
  padding-right: var(--spacing-9) !important;
}

.pt-9 {
  padding-top: var(--spacing-9) !important;
}

.pb-9 {
  padding-bottom: var(--spacing-9) !important;
}
