.root {
  margin-right: var(--mini-unit);
  padding: var(--spacing-1);
  margin-left: calc(-1 * var(--spacing-1));

  line-height: 1;
}

.root:hover {
    background-color: var(--palette-grey-200);
    border-radius: var(--round-corners);
    border-style: none;
  }

.root:active {
    background-color: var(--palette-grey-200);
    border-radius: var(--round-corners);
    border-style: none;
  }
