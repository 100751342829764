.icon {
  margin-right: var(--spacing-2);

  height: 1rem;
  overflow: hidden;
}

.button {
  border-style: solid;
  border-width: 1px;

  color: var(--palette-text-000);
}

.button:not(.disabled),
  .button:not(:disabled) {
    background-color: #3B5998;
    border-color: #3B5998;
  }

.button:hover, .button.mouseHover {
    background-color: #4467b0;
    border-color: #4467b0;
  }

.button:active, .button.active {
    background-color: #6583c3;
    border-color: #6583c3;
  }

.button.disabled {
    color: var(--palette-grey-400);
    background-color: var(--palette-grey-200);
    border-color: var(--palette-grey-200);
  }
